import { render, staticRenderFns } from "./oauth_config.vue?vue&type=template&id=b1241c8e&scoped=true&"
import script from "./oauth_config.vue?vue&type=script&lang=js&"
export * from "./oauth_config.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1241c8e",
  null
  
)

export default component.exports